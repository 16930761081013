//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-436:_3784,_2356,_9988,_8720,_9116,_3312,_2485,_4676;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-436')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-436', "_3784,_2356,_9988,_8720,_9116,_3312,_2485,_4676");
        }
      }catch (ex) {
        console.error(ex);
      }