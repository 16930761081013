
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function verifyFitmentRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3525'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3727'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select form-select',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', '', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7481'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7484'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'form-input cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'key': '2847'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', '', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    function repeatSelects2(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3525'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3727'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select form-select',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', '', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7481'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7484'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'form-input cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'key': '2847'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', '', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__verify-fitment' }, this.template === 'active' ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
        'key': '68'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, [_createElement('svg', {
            'width': '22',
            'height': '20',
            'viewBox': '0 0 22 20',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '2660'
        }, _createElement('path', {
            'd': 'M11 4.49L18.53 17.5H3.47L11 4.49ZM11 0.5L0 19.5H22L11 0.5ZM12 14.5H10V16.5H12V14.5ZM12 8.5H10V12.5H12V8.5Z',
            'fill': '#F29508'
        }))]), '\n      Add a vehicle to check fitment\n    '), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle
    }, '\n    ADD\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button button--secondary',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle
    }, '\n    Clear\n  ')))) : null, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label-container cm_' + this.fits,
        'key': '6873'
    }, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__' + this.fits + ' cmTemplate_locked',
        'key': '6985'
    }, _createElement('div', { 'className': 'cm_verify-fitment_fitment__container' }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'width': '20',
            'height': '21',
            'viewBox': '0 0 20 21',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '72410'
        }, _createElement('path', {
            'd': 'M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM14.59 6.08L8 12.67L5.41 10.09L4 11.5L8 15.5L16 7.5L14.59 6.08Z',
            'fill': '#2A7E56'
        }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'width': '20',
            'height': '21',
            'viewBox': '0 0 20 21',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '77650'
        }, _createElement('path', {
            'd': 'M9 13.5H11V15.5H9V13.5ZM9 5.5H11V11.5H9V5.5ZM9.99 0.5C4.47 0.5 0 4.98 0 10.5C0 16.02 4.47 20.5 9.99 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 9.99 0.5ZM10 18.5C5.58 18.5 2 14.92 2 10.5C2 6.08 5.58 2.5 10 2.5C14.42 2.5 18 6.08 18 10.5C18 14.92 14.42 18.5 10 18.5Z',
            'fill': '#EB4714'
        }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '82360'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, this.fits === 'yes' ? ['Compatible with'] : null, this.fits === 'no' ? ['Does Not Fit'] : null), _createElement('div', { 'className': 'cm_verify-fitment_body' }, _createElement('span', { 'className': 'cm_verify-fitment_vehicle' }, this.vehicleString || 'Vehicle'), _createElement('a', {
        'className': 'cm_verify-fitment_change',
        'onClick': this.changeVehicle
    }, 'Change'))))) : null, this.fits === 'no' ? _createElement('div', {
        'className': 'cm_verify-fitment_compatible-parts-container',
        'key': '9337'
    }, _createElement('a', {
        'href': this.getVehicleUrl(),
        'className': 'cm_verify-fitment_compatible-parts'
    }, 'Click here'), _createElement('span', {}, ' to shop parts that fit this vehicle.')) : null) : null, this.template === 'inexact' ? _createElement('div', {
        'className': 'cm_vehicle-widget_specifier cmTemplate_inexact',
        'key': '9618'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, 'Please specify ', this.selectFields.join(', '), ' for your ', this.vehicleString), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects2.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button button button--primary button button--primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle
    }, '\n    ADD\n  ')))) : null, this.template === 'universal' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__universal cmTemplate_universal',
        'key': '15942'
    }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'width': '20',
            'height': '21',
            'viewBox': '0 0 20 21',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '161410'
        }, _createElement('path', {
            'd': 'M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM14.59 6.08L8 12.67L5.41 10.09L4 11.5L8 15.5L16 7.5L14.59 6.08Z',
            'fill': '#2A7E56'
        }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'width': '20',
            'height': '21',
            'viewBox': '0 0 20 21',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '166650'
        }, _createElement('path', {
            'd': 'M9 13.5H11V15.5H9V13.5ZM9 5.5H11V11.5H9V5.5ZM9.99 0.5C4.47 0.5 0 4.98 0 10.5C0 16.02 4.47 20.5 9.99 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 9.99 0.5ZM10 18.5C5.58 18.5 2 14.92 2 10.5C2 6.08 5.58 2.5 10 2.5C14.42 2.5 18 6.08 18 10.5C18 14.92 14.42 18.5 10 18.5Z',
            'fill': '#EB4714'
        }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '171360'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, window.Convermax.config?.verifyFitmentUniversalTitle || 'Universal Fit'), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, window.Convermax.config?.verifyFitmentUniversalText || 'This product may require modification.'))) : null, this.template === 'unknown' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__unknown cmTemplate_unknown',
        'key': '18002'
    }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'width': '20',
            'height': '21',
            'viewBox': '0 0 20 21',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '181950'
        }, _createElement('path', {
            'd': 'M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM14.59 6.08L8 12.67L5.41 10.09L4 11.5L8 15.5L16 7.5L14.59 6.08Z',
            'fill': '#2A7E56'
        }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'width': '20',
            'height': '21',
            'viewBox': '0 0 20 21',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '187190'
        }, _createElement('path', {
            'd': 'M9 13.5H11V15.5H9V13.5ZM9 5.5H11V11.5H9V5.5ZM9.99 0.5C4.47 0.5 0 4.98 0 10.5C0 16.02 4.47 20.5 9.99 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 9.99 0.5ZM10 18.5C5.58 18.5 2 14.92 2 10.5C2 6.08 5.58 2.5 10 2.5C14.42 2.5 18 6.08 18 10.5C18 14.92 14.42 18.5 10 18.5Z',
            'fill': '#EB4714'
        }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '191900'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, 'No Fitment Data'), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, 'This product doesn\'t have fitment application data. Please verify fitment manually.'))) : null);
}
        export const componentNames = ["cm:filterInput","cm:filterInput"]